/**
 * Vuex Store for managing state in the application.
 *
 * State:
 * - location: Object holding storeId and pickupLocation, persisted in localStorage.
 * - userQuery: Contains user's query parameters like bannerId, categoryName, etc.
 * - productModalState: State for managing the visibility of the product details modal.
 * - locationModalState: State for managing the visibility of the location selection modal.
 * - cartModalState: State for managing the visibility of the cart modal.
 * - searchModalState: State for managing the visibility of the search modal.
 * - timeSelectionModalState: State for managing the visibility of the time selection modal.
 * - cartItems: Object representing the items currently in the user's cart.
 * - cartChanges: Array tracking changes to the cart for debounced API updates.
 * - isCartLoading: Boolean indicating if the cart is currently being updated.
 * - categories: Array of product categories, initially loaded from localStorage and then updated from the server.
 * - pickupPoints: Array of pickup points fetched from the server.
 * - pickupTimes: Array of available pickup times fetched from the server.
 * - user: Object containing user information, persisted in localStorage.
 * - token: String containing the JWT token, persisted in localStorage.
 * - refreshToken: String containing the refresh token, persisted in localStorage.
 *
 * Mutations:
 * - setLocation: Sets the storeId and pickupLocation, and persists them in localStorage.
 * - setPickupTimes: Sets the available pickup times.
 * - clearLocation: Clears the storeId and pickupLocation from state and localStorage.
 * - showProductModal: Shows the product details modal.
 * - hideProductModal: Hides the product details modal.
 * - toggleLocationModal: Toggles the visibility of the location selection modal.
 * - hideLocationModal: Hides the location selection modal.
 * - toggleCartModal: Toggles the visibility of the cart modal.
 * - hideCartModal: Hides the cart modal.
 * - toggleSearchModal: Toggles the visibility of the search modal.
 * - hideSearchModal: Hides the search modal.

 * - addToCart: Adds a product to the cart.
 * - removeFromCart: Removes a product from the cart.
 * - clearCart: Clears the cart and updates localStorage.
 * - syncLocalCartWithServer: Synchronizes the local cart with the server response.
 * - setCartLoading: Sets the loading state for the cart.
 * - setCategories: Sets the categories array and updates localStorage.
 * - setPickupPoints: Sets the pickup points array.
 * - setUser: Sets the user information and updates localStorage.
 * - setToken: Sets the JWT token and updates localStorage.
 * - setRefreshToken: Sets the refresh token and updates localStorage.
 * - clearAuthData: Clears the authentication data from the state and localStorage.
 *
 * Actions:
 * - initializeLocation: Loads the storeId and pickupLocation from localStorage upon store creation.
 * - updateSelectedPickupTime: Updates the selected pickup time.
 * - closeProductModal: Hides the product details modal.
 * - toggleLocationModal: Toggles the visibility of the location selection modal.
 * - hideLocationModal: Hides the location selection modal.
 * - toggleCartModal: Toggles the visibility of the cart modal.
 * - toggleSearchModal: Toggles the visibility of the search modal.
 * - hideSearchModal: Hides the search modal.
 * - selectLocation: Sets the storeId and pickupLocation.
 * - addToCart: Adds a product to the cart and updates the server.
 * - removeFromCart: Removes a product from the cart and updates the server.
 * - clearCart: Clears the cart by sending a request to the server.
 * - fetchCategories: Fetches the categories from the server and updates the state and localStorage.
 * - initializeApp: Initializes the application state, including fetching categories and setting up location.
 * - initializeCart: Initializes a new cart when a store is selected.
 * - userLoggedIn: Handles user login, storing user info and tokens.
 * - updateToken: Updates the JWT token.
 * - userLoggedOut: Handles user logout, clearing authentication data.
 *
 * Getters:
 * - location: Provides access to the location state.
 * - userQuery: Provides access to the userQuery state.
 * - productModalState: Provides access to the productModalState.
 * - locationModalState: Provides access to the locationModalState.
 * - cartItems: Provides access to the cartItems state.
 * - isCartVisible: Indicates if the cart modal is visible.
 * - isSearchVisible: Indicates if the search modal is visible.
 * - categories: Provides access to the categories state.
 * - pickupPoints: Provides access to the pickupPoints state.
 * - pickupTimes: Provides access to the pickupTimes state.
 */

import { createStore } from "vuex";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import PickupPointAPI from "@/api/PickupPointAPI";
import PickupTimeAPI from "@/api/PickupTimeAPI";
import CartAPI from "@/api/CartAPI";
import UserAPI from "@/api/UserAPI";
import ShoppingListAPI from "./api/SaveAPI";
import { auth } from "@/plugins/firebaseconfig";
import vtoast from "./store/vtoast";
import { getField, updateField } from "vuex-map-fields";
import { shutdown, boot, update } from "@intercom/messenger-js-sdk";
import {
  log_add_to_cart,
  log_remove_form_cart,
  log_begin_checkout,
  log_purchase,
} from "./plugins/analytics";
let debounceTimer;
const debounceDelay = 1000; // 1 second delay for debouncing actions

const store = createStore({
  modules: {
    vtoast,
  },
  state: {
    location: {
      storeId: localStorage.getItem("storeId") || "",
      pickupLocation: localStorage.getItem("pickupLocation") || null,
    },
    userQuery: {
      homePage: true,
      bannerId: null,
      textBannerId: null,
      categoryName: null,
      searchTerm: null,
      userFilter: {},
    },
    currentLang: localStorage.getItem("currentLang") || "sv",
    locationModalIsVisible: false,
    cartModalIsVisible: false,
    categoryModalIsVisible: window.innerWidth >= 768,
    searchTerm: "",
    cart: JSON.parse(localStorage.getItem("cart")) || {},
    localCart: {
      items: [],
      total: 0,
      totalItems: 0,
      recipes: [],
      shoppingLists: [],
    },
    mainContentWidth: 1000,
    cartChanges: [],
    isCartLoading: false,
    categories: JSON.parse(localStorage.getItem("categories")) || [],
    pickupPoints: [],
    pickupTimes: [],
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("jwtToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null,
  },

  mutations: {
    updateField,
    /**
     * Sets the storeId and pickupLocation in the state and persists them in localStorage.
     * Dispatches initializeCart if the storeId was previously null.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} payload - The payload containing storeId and pickupLocation.
     */
    async setLocation(state, { storeId, pickupLocation, pickupTime }) {
      state.location.storeId = storeId;
      state.location.pickupLocation = pickupLocation;
      localStorage.setItem("storeId", storeId);
      localStorage.setItem("pickupLocation", pickupLocation);
    },

    /**
     * Clears the storeId and pickupLocation from the state and localStorage.
     * @param {Object} state - The current state of the Vuex store.
     */
    clearLocation(state) {
      state.location.storeId = null;
      state.location.pickupLocation = null;
      localStorage.removeItem("storeId");
      localStorage.removeItem("pickupLocation");
      state.locationModalState.isVisible = true;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    toggleCartModal(state) {
      state.cartModalIsVisible = !state.cartModalIsVisible;
      state.locationModalIsVisible = false;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    setCategoryModal(state, modal) {
      state.categoryModalIsVisible = modal;
    },

    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    setCurrentLang(state, lang) {
      state.currentLang = lang;

      localStorage.setItem("currentLang", lang);
    },
    /**
     * Toggles the visibility of the cart modal and hides other modals.
     * @param {Object} state - The current state of the Vuex store.
     */
    toggleLocationModal(state) {
      state.cartModalIsVisible = false;
      state.locationModalIsVisible = !state.locationModalIsVisible;
    },

    SET_CART(state, newCart) {
      localStorage.setItem("cart", JSON.stringify(newCart));
      state.cart = newCart;
    },
    /**
     * Synchronizes the local cart with the server response.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} newCart - The new cart object received from the server.
     */
    syncLocalCartWithServer(state) {
      state.localCart.total = state.cart.price.products;
      state.localCart.totalItems = state.cart.totalItems;
      state.localCart.items = [];
      state.localCart.recipes = JSON.parse(JSON.stringify(state.cart.recipes));
      state.localCart.shoppingLists = JSON.parse(
        JSON.stringify(state.cart.shoppingLists)
      );

      for (const prod of state.cart.productVariants) {
        state.localCart.items.push({
          id: prod.productVariant._id,
          quantity: prod.quantity,
          recipeQuantity: prod.recipeQuantity ? prod.recipeQuantity : 0,
          shoppingListQuantity: prod.shoppingListQuantity
            ? prod.shoppingListQuantity
            : 0,
        });
      }
    },

    /**
     * Sets the loading state for the cart.
     * @param {Object} state - The current state of the Vuex store.
     * @param {boolean} isLoading - The loading state to be set.
     */
    setCartLoading(state, isLoading) {
      state.isCartLoading = isLoading;
    },

    /**
     * Sets the categories array and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Array} categories - The categories array to be set.
     */
    setCategories(state, categories) {
      state.categories = categories;
      localStorage.setItem("categories", JSON.stringify(categories));
    },

    /**
     * Sets the user information and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {Object} user - The user information to be set.
     */
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },

    /**
     * Sets the JWT token and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {string} token - The JWT token to be set.
     */
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("jwtToken", token);
    },

    /**
     * Sets the refresh token and updates localStorage.
     * @param {Object} state - The current state of the Vuex store.
     * @param {string} refreshToken - The refresh token to be set.
     */
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      localStorage.setItem("refreshToken", refreshToken);
    },

    /**
     * Clears the authentication data from the state and localStorage.
     * @param {Object} state - The current state of the Vuex store.
     */
    clearAuthData(state) {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem("user");
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("refreshToken");
    },
  },

  actions: {
    async setLocation(
      { state, commit, dispatch },
      { storeId, pickupLocation, pickupTime }
    ) {
      await commit("setLocation", { storeId, pickupLocation, pickupTime });
      if (
        state.location.pickupLocation != null &&
        state.location.pickupLocation != "null" &&
        (state.location.storeId != state.cart.storeId ||
          state.location.pickupLocation != state.cart.pickupPoint) &&
        state.cart._id != null
      ) {
        const updatebody = {
          storeId: state.location.storeId,
          pickupPoint: state.location.pickupLocation,
        };

        const newCart = await CartAPI.moveCart(updatebody);

        await dispatch("SET_CART", newCart.data);
      }
      if (pickupTime != null) {
        const newCart = await CartAPI.changePickupTime(pickupTime);

        await dispatch("SET_CART", newCart.data);
      }
    },

    async SET_CART({ commit, dispatch }, newCart) {
      if (newCart.status == "archived") {
        await dispatch("createCart");
        return;
      }
      await commit("SET_CART", newCart);
    },
    /**
     * Initializes the location by loading the storeId and pickupLocation from localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async initializeLocation({ commit, dispatch }) {
      const storeId =
        localStorage.getItem("storeId") != null
          ? localStorage.getItem("storeId")
          : process.env.VITE_APP_STOREID;

      /*const pickupLocation =
        localStorage.getItem("pickupLocation") != null
          ? localStorage.getItem("pickupLocation")
          : "667e6eff8f48b18783b69f75";*/
      if (storeId) {
        await dispatch("setLocation", {
          storeId,
          pickupLocation: null,
          pickupTime: null,
        });
      }
    },

    /**
     * Toggles the visibility of the location selection modal.
     * @param {Object} context - The Vuex action context.
     */
    toggleLocationModal({ commit }) {
      commit("toggleLocationModal");
    },

    /**
     * Toggles the visibility of the cart modal.
     * @param {Object} context - The Vuex action context.
     */
    toggleCartModal({ commit }) {
      commit("toggleCartModal");
    },

    setCategoryModal({ commit }, modal) {
      commit("setCategoryModal", modal);
    },

    async setCurrentLang({ commit, dispatch, state }, lang) {
      await commit("setCurrentLang", lang);
      await dispatch("fetchCategories");
      if (state.cart && state.cart._id) {
        const resp = await CartAPI.fetchCart(state.cart._id);
        const storedCart = resp.data;
        await dispatch("SET_CART", storedCart);
      } else {
        await dispatch("createCart");
      }
    },
    /**
     * Sets the storeId and pickupLocation.
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload containing storeId and pickupLocation.
     */
    async selectLocation(
      { dispatch, commit },
      { storeId, pickupLocation, pickupTime }
    ) {
      await dispatch("setLocation", { storeId, pickupLocation, pickupTime });
    },

    async createCart({ commit, dispatch }) {
      const newCart = await CartAPI.createCart();
      await dispatch("SET_CART", newCart.data);
      await commit("syncLocalCartWithServer");
    },

    async removeRecipeAndShoppingList(
      { state, dispatch },
      { i, id, quantity }
    ) {
      let totRemoveQuantity =
        state.localCart.items[i].recipeQuantity +
        state.localCart.items[i].shoppingListQuantity -
        state.localCart.items[i].quantity;

      const removedShopping = await dispatch("removeShoppingLocalCart", {
        totRemoveQuantity,
        id,
      });
      state.localCart.items[i].shoppingListQuantity -= removedShopping;
      totRemoveQuantity -= removedShopping;

      const removedRecipe = await dispatch("removeRecipeLocalCart", {
        totRemoveQuantity,
        id,
      });
      state.localCart.items[i].recipeQuantity -= removedRecipe;
      totRemoveQuantity -= removedRecipe;
    },

    changeProductLocalCart(
      { state },
      { id, quantity, recipeId, shoppingId, product }
    ) {
      const i = state.localCart.items.findIndex((element) => element.id == id);

      if (i != -1) {
        const newQuantity = Math.max(
          0,
          state.localCart.items[i].quantity + quantity
        );
        if (shoppingId) {
          state.localCart.items[i].shoppingListQuantity = Math.max(
            0,
            state.localCart.items[i].shoppingListQuantity + quantity
          );
        }
        if (recipeId) {
          state.localCart.items[i].recipeQuantity = Math.max(
            0,
            state.localCart.items[i].recipeQuantity + quantity
          );
        }

        if (newQuantity == 0) {
          log_remove_form_cart(product, -quantity);
          state.localCart.items.splice(i, 1);
        } else {
          quantity < 0
            ? log_remove_form_cart(product, -quantity)
            : log_add_to_cart(product, quantity);
          state.localCart.items[i].quantity = newQuantity;
        }
      } else {
        log_add_to_cart(product, quantity);
        state.localCart.items.push({
          id: id,
          quantity: quantity,
          recipeQuantity: recipeId ? quantity : 0,
          shoppingListQuantity: shoppingId ? quantity : 0,
        });
      }
    },

    async changeRecipeLocalCart(
      { state, dispatch },
      { i, id, quantity, recipeId, product }
    ) {
      const recipe = state.localCart.recipes.findIndex(
        (r) => r.recipe == recipeId
      );
      let productIndex = state.localCart.recipes[recipe].products.findIndex(
        (r) => r.productId == id
      );

      if (product == -1) {
        if (quantity > 0) {
          state.localCart.recipes[recipe].products.push({
            productId: id,
            quantity: quantity,
          });
        }
      } else {
        const newQuantity = Math.max(
          0,
          state.localCart.recipes[recipe].products[productIndex].quantity +
            quantity
        );
        if (newQuantity === 0) {
          state.localCart.recipes[recipe].products.splice(productIndex, 1);
        } else {
          state.localCart.recipes[recipe].products[productIndex].quantity =
            newQuantity;
        }
      }

      await dispatch("changeProductLocalCart", {
        i,
        id,
        quantity,
        recipeId,
        product,
      });
    },

    async changeShoppingLocalCart(
      { state, dispatch },
      { i, id, quantity, shoppingId, product }
    ) {
      const shoppingList = state.localCart.shoppingLists.findIndex(
        (r) => r.shoppingList == shoppingId
      );
      let productIndex = state.localCart.shoppingLists[
        shoppingList
      ].products.findIndex((r) => r.productId == id);

      if (productIndex == -1) {
        if (quantity > 0) {
          state.localCart.shoppingLists[shoppingList].products.push({
            productId: id,
            quantity: quantity,
          });
        }
      } else {
        const newQuantity = Math.max(
          0,
          state.localCart.shoppingLists[shoppingList].products[productIndex]
            .quantity + quantity
        );
        if (newQuantity === 0) {
          state.localCart.shoppingLists[shoppingList].products.splice(
            productIndex,
            1
          );
        } else {
          state.localCart.shoppingLists[shoppingList].products[
            productIndex
          ].quantity = newQuantity;
        }
      }

      await dispatch("changeProductLocalCart", {
        i,
        id,
        quantity,
        shoppingId,
        product,
      });
    },

    removeRecipeLocalCart({ state }, { totRemoveQuantity, id }) {
      let recipeRemoved = 0;
      for (const index in state.localCart.recipes) {
        const product = state.localCart.recipes[index].products.findIndex(
          (r) => r.productId == id
        );
        if (product != -1 && totRemoveQuantity > 0) {
          const newQuantity =
            totRemoveQuantity >
            state.localCart.recipes[index].products[product].quantity
              ? 0
              : state.localCart.recipes[index].products[product].quantity -
                totRemoveQuantity;
          recipeRemoved =
            recipeRemoved +
            (state.localCart.recipes[index].products[product].quantity -
              newQuantity);
          totRemoveQuantity =
            totRemoveQuantity -
            (state.localCart.recipes[index].products[product].quantity -
              newQuantity);
          state.localCart.recipes[index].products[product].quantity =
            newQuantity;
        }
      }
      return recipeRemoved;
    },

    removeShoppingLocalCart({ state }, { totRemoveQuantity, id }) {
      let shoppingRemoved = 0;
      for (const index in state.localCart.shoppingLists) {
        const product = state.localCart.shoppingLists[index].products.findIndex(
          (r) => r.productId == id
        );
        if (product != -1 && totRemoveQuantity > 0) {
          const newQuantity =
            totRemoveQuantity >
            state.localCart.shoppingLists[index].products[product].quantity
              ? 0
              : state.localCart.shoppingLists[index].products[product]
                  .quantity - totRemoveQuantity;
          shoppingRemoved =
            shoppingRemoved +
            (state.localCart.shoppingLists[index].products[product].quantity -
              newQuantity);
          totRemoveQuantity =
            totRemoveQuantity -
            (state.localCart.shoppingLists[index].products[product].quantity -
              newQuantity);
          state.localCart.shoppingLists[index].products[product].quantity =
            newQuantity;
        }
      }
      return shoppingRemoved;
    },

    async changeLocalCart(
      { commit, state, dispatch },
      { id, quantity, recipeId, shoppingId, product }
    ) {
      const i = state.localCart.items.findIndex((element) => element.id == id);
      if (recipeId != "") {
        await dispatch("changeRecipeLocalCart", {
          i,
          id,
          quantity,
          recipeId,
          product,
        });
      } else if (shoppingId != "") {
        await dispatch("changeShoppingLocalCart", {
          i,
          id,
          quantity,
          shoppingId,
          product,
        });
      } else {
        await dispatch("changeProductLocalCart", {
          i,
          id,
          quantity,
          product,
        });
      }

      const newIndex = state.localCart.items.findIndex(
        (element) => element.id == id
      );
      if (
        newIndex != -1 &&
        state.localCart.items[newIndex].recipeQuantity +
          state.localCart.items[newIndex].shoppingListQuantity >
          state.localCart.items[newIndex].quantity
      ) {
        await dispatch("removeRecipeAndShoppingList", {
          i: newIndex,
          id,
          quantity,
        });
      }

      state.localCart.totalItems = state.localCart.items.reduce(
        (accumulator, item) => {
          return accumulator + item.quantity;
        },
        0
      );
    },

    async removePromoCode({ commit, state, dispatch }) {
      const response = await CartAPI.removePromotion();
      const updatedCart = response.data;
      dispatch("SET_CART", updatedCart);
    },

    async validatePromoCode({ commit, state, dispatch }, { code }) {
      const response = await CartAPI.validatePromoCode({ code: code });
      const updatedCart = response.data;
      dispatch("SET_CART", updatedCart);
    },

    async changeCart(
      { commit, state, dispatch },
      { id, quantity, recipeId, shoppingId }
    ) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.changeProductInCart(state.cart._id, {
            productId: id,
            quantity: quantity,
            shoppingId: shoppingId,
            recipeId: recipeId,
          });
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("changeCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },

    async updateReplacementPreferences({ commit, state, dispatch }, { body }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.updateReplacementPreferences(
            state.cart._id,
            body
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("changeCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },

    async addProductsCart({ commit, state, dispatch }, { products }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.addProductsInCart(
            state.cart._id,
            products
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },
    async removeRecipeCart({ commit, state, dispatch }, { recipeId }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.removeRecipeCart(
            state.cart._id,
            recipeId
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },
    async updateRecipeCart({ commit, state, dispatch }, { recipe }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.updateRecipeCart(
            state.cart._id,
            recipe
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },
    async addRecipeCart({ commit, state, dispatch }, { recipe }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.addRecipeCart(state.cart._id, recipe);
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },

    async addShoppingListCart({ commit, state, dispatch }, { shoppingList }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.addShoppingListCart(
            state.cart._id,
            shoppingList
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },
    async removeShoppingListCart({ commit, state, dispatch }, { shoppingId }) {
      try {
        if (state.cart && state.cart._id) {
          commit("setCartLoading", true);
          const response = await CartAPI.removeShoppingListCart(
            state.cart._id,
            shoppingId
          );
          const updatedCart = response.data;
          if (updatedCart.status == "archived") {
            await dispatch("createCart");
            await dispatch("addProductsCart", { id, quantity });
          }
          dispatch("SET_CART", updatedCart);
          commit("syncLocalCartWithServer");
          commit("setCartLoading", false);
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        commit("syncLocalCartWithServer");
        commit("setCartLoading", false);
      }
    },

    /**
     * Clears the cart by sending a request to the server and updates the state with the new cart.
     * @param {Object} context - The Vuex action context.
     */
    async clearCart({ commit, dispatch, state }) {
      if (state.cart.totalItems === 0) {
        return;
      }
      commit("setCartLoading", true);
      const updatedCart = await CartAPI.emptyCart(state.cart._id);
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer");
      commit("setCartLoading", false);
    },

    async reserveCart({ commit, dispatch, state }) {
      const updatedCart = await CartAPI.reserveCart();
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer", updatedCart.data);
    },

    async unReserveCart({ commit, dispatch, state }) {
      const updatedCart = await CartAPI.unReserveCart();
      dispatch("SET_CART", updatedCart.data);
      commit("syncLocalCartWithServer", updatedCart.data);
    },
    /**
     * Checks out the cart and updates the state with the new cart object returned from the server.
     * Ensures userId is set before checkout.
     * @param {Object} context - The Vuex action context.
     */
    async checkoutCart({ commit, dispatch, state }) {
      try {
        commit("setCartLoading", true);

        // Ensure userId is set before checkout
        if (!state.cart.userId || state.cart.userId == "") {
          const updatedCart = await CartAPI.updateCart({
            userId: state.user._id,
          });

          dispatch("SET_CART", updatedCart.data);
          commit("syncLocalCartWithServer", updatedCart.data);
        }

        const newCart = await CartAPI.checkoutCart();
        log_begin_checkout(newCart.data);
        dispatch("SET_CART", newCart.data);
        commit("syncLocalCartWithServer", newCart.data);
        // Handle post-checkout logic here (e.g., redirecting to a success page)
      } catch (error) {
        console.error("Error during checkout:", error);
      } finally {
        commit("setCartLoading", false);
      }
    },

    async cartPurchased({ commit, dispatch, state }) {
      try {
        log_purchase(state.cart);
        await dispatch("createCart");
        // Handle post-checkout logic here (e.g., redirecting to a success page)
      } catch (error) {
        console.error("Error during checkout:", error);
      } finally {
        commit("setCartLoading", false);
      }
    },
    /**
     * Fetches the categories from the server and updates the state and localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async fetchCategories({ commit }) {
      try {
        const response = await CategoryProductAPI.getAllCategories();
        commit("setCategories", response.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    },

    /**
     * Initializes the application state, including fetching categories and setting up location.
     * @param {Object} context - The Vuex action context.
     */
    async initializeApp({ dispatch, state }) {
      await dispatch("initializeLocation");
      await dispatch("initializeCart");
      await dispatch("fetchCategories");

      this.watch(
        () => state.location.storeId,
        (newStoreId) => {
          if (newStoreId) {
            dispatch("fetchCategories");
          }
        }
      );
    },

    /**
     * Initializes a new cart when a store is selected.
     * If there is an existing cart in localStorage, it syncs with the server.
     * Otherwise, it creates a new cart.
     * @param {Object} context - The Vuex action context.
     */
    async initializeCart({ dispatch, state, commit }) {
      try {
        if (state.cart && state.cart._id) {
          const resp = await CartAPI.fetchCart(state.cart._id);
          const storedCart = resp.data;

          await dispatch("SET_CART", storedCart);
          await commit("syncLocalCartWithServer");
        } else {
          await dispatch("createCart");
        }
      } catch (error) {
        console.error("Error initializing cart:", error);

        await dispatch("createCart");
      }
    },

    /**
     * Handles user login, storing user info and tokens in the state and localStorage.
     * @param {Object} context - The Vuex action context.
     * @param {Object} payload - The payload containing user info, token, and refreshToken.
     */
    async userLoggedIn(
      { commit, state, dispatch },
      { user, token, refreshToken }
    ) {
      commit("setUser", user);
      commit("setToken", token);
      commit("setRefreshToken", refreshToken);

      // If cart exists and is missing userId, update it

      if (state.cart._id) {
        try {
          const updatedCart = await CartAPI.updateCart({ userId: user._id });
          console.log("CARTUPDATED!!!");
          dispatch("SET_CART", updatedCart.data);
          commit("syncLocalCartWithServer", updatedCart.data);
        } catch (error) {
          console.error("Error updating cart userId:", error);
        }
      }
    },

    async updateUser({ commit, state, dispatch }, body) {
      try {
        const user = await UserAPI.updateUser(state.user._id, body);

        console.log("USER", user);
        commit("setUser", user);
      } catch (error) {
        console.error("Error updating cart userId:", error);
      }
    },

    async setUser({ commit, state, dispatch }, user) {
      console.log("USER", user);
      commit("setUser", user);
    },

    /**
     * Updates the JWT token.
     * @param {Object} context - The Vuex action context.
     * @param {string} token - The new JWT token.
     */
    async updateToken({ commit }, token) {
      commit("setToken", token);
    },

    async fetchUser({ commit, dispatch }) {
      if (auth.currentUser == null) {
        await dispatch("userLoggedOut");
      } else {
        /*
        const firebaseToken = await auth.currentUser.getIdToken(
           forceRefresh  true
        );
        const resp = await UserAPI.loginUser(firebaseToken);
        console.log(resp);
        const data = resp.data;
        await dispatch("userLoggedIn", {
          user: data,
          token: firebaseToken,
          refreshToken: "",
        });
    
      */
      }
    },

    /**
     * Handles user logout, clearing authentication data from the state and localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async userLoggedOut({ commit, state, dispatch }) {
      commit("clearAuthData");
      auth.signOut();
      if (state.cart._id) {
        try {
          const updatedCart = await CartAPI.updateCart({ userId: "" });
          dispatch("SET_CART", updatedCart.data);
          commit("syncLocalCartWithServer", updatedCart.data);
        } catch (error) {
          console.error("Error updating cart userId:", error);
        }
      }
    },

    /**
     * Handles user logout, clearing authentication data from the state and localStorage.
     * @param {Object} context - The Vuex action context.
     */
    async likeRecipeUser({ commit }, { recipeId, like }) {
      const user = await ShoppingListAPI.likeRecipe({
        recipeId: recipeId,
        like: like,
      });
      commit("setUser", user);
    },
  },

  getters: {
    getField,
    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    location: (state) => state.location,

    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    locationModalIsVisible: (state) => state.locationModalIsVisible,

    /**
     * Provides access to the location state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The location state.
     */
    cartModalIsVisible: (state) => state.cartModalIsVisible,

    categoryModalIsVisible: (state) => state.categoryModalIsVisible,
    /**
     * Provides access to the userQuery state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The userQuery state.
     */
    userQuery: (state) => state.userQuery,
    categoryModalIsVisible: (state) => state.categoryModalIsVisible,
    /**
     * Provides access to the cart state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The cart state.
     */
    cart: (state) => state.cart,

    /**
     * Provides access to the local cart state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Object} The local cart state.
     */
    localCart: (state) => state.localCart,
    /**
     * Provides access to the categories state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The categories state.
     */
    categories: (state) => state.categories,

    /**
     * Provides access to the pickupPoints state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupPoints state.
     */
    pickupPoints: (state) => state.pickupPoints,

    /**
     * Provides access to the pickupTimes state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupTimes state.
     */
    currentLang: (state) => state.currentLang,
    /**
     * Provides access to the pickupTimes state.
     * @param {Object} state - The current state of the Vuex store.
     * @returns {Array} The pickupTimes state.
     */
    pickupTimes: (state) => state.pickupTimes,

    searchTerm: (state) => state.searchTerm,

    mainContentWidth: (state) => state.mainContentWidth,
  },
});

export default store;
