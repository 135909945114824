<template>
  <v-dialog
    v-model="show"
    scrollable
    :width="$vuetify.display.xs || $vuetify.display.sm ? '100vw' : '37vw'"
    @click:outside="decline"
  >
    <v-card class="pa-2" color="primary">
      <!-- Back Arrow Icon -->
      <v-row
        align="start"
        :justify="step > 1 ? 'space-between' : 'end'"
        class="pa-2"
      >
        <v-btn
          v-if="step > 1"
          elevation="0"
          @click="
            {
              step = 1;
              isGoogleLoading = false;
              isAppleLoading = false;
              createAccountLoading = false;
            }
          "
          variant="text"
          icon="mdi-arrow-left"
          :size="
            $vuetify.display.xs || $vuetify.display.sm ? 'small' : 'x-large'
          "
          aria-label="Vänster"
        ></v-btn>
        <v-btn
          elevation="0"
          @click="decline"
          variant="text"
          icon="mdi-close"
          size="x-large"
          aria-label="Stäng"
        ></v-btn>
      </v-row>
      <v-card-text class="pa-2 pt-0">
        <v-window disabled v-model="step">
          <v-window-item disabled :value="1">
            <v-col align="start" justify="start" class="pa-0">
              <!--  <v-img
          src="../../src/assets/images/ns_white.png"
          class="header-image"
          contain
        ></v-img>-->
              <p class="bold-h1">{{ $t("create_account_or_log_in") }}</p>

              <v-card class="pa-2 mt-10">
                <!-- Email Field -->
                <v-form
                  v-model="email_from"
                  ref="email_from"
                  @submit.prevent="checkAccountExists"
                >
                  <div class="form-group">
                    <p class="bold-h4 mb-2">{{ $t("what_is_your_email") }}</p>
                    <v-text-field
                      id="email"
                      v-model="user.email"
                      :rules="emailRules"
                      type="email"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      :disabled="isLoading"
                    />
                  </div>
                  <v-btn
                    type="submit"
                    color="primary"
                    size="large"
                    class="xl-button text-none"
                    block
                    :disabled="isLoading"
                  >
                    {{ $t("continue_with_email") }}
                  </v-btn>
                </v-form>
                <!-- Separation Line -->
                <v-col class="body-2 pa-4" align="center"
                  ><v-row align="center" justify="center"
                    ><v-col sm="4"> <v-divider></v-divider></v-col>
                    <p class="body-2">{{ $t("or") }}</p>
                    <v-col sm="4"> <v-divider></v-divider></v-col></v-row
                ></v-col>
                <!-- Custom OAuth Provider Buttons -->
                <div class="button-container">
                  <button
                    class="gsi-material-button"
                    @click="signInWithGoogle"
                    :disabled="isLoading"
                  >
                    <div class="gsi-material-button-state"></div>
                    <div class="gsi-material-button-content-wrapper">
                      <div class="gsi-material-button-icon">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          style="display: block"
                        >
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                      </div>
                      <span class="gsi-material-button-contents">{{
                        $t("continue_with_google")
                      }}</span>
                      <span style="display: none">{{
                        $t("continue_with_google")
                      }}</span>
                    </div>
                  </button>

                  <button
                    class="gsi-material-button mt-4"
                    @click="signInWithApple"
                    :disabled="isLoading"
                    style="background-color: black"
                  >
                    <div class="gsi-material-button-state"></div>
                    <div class="gsi-material-button-content-wrapper">
                      <div class="gsi-material-button-icon pb-4">
                        <font-awesome-icon
                          class="pb-4"
                          icon="fa-brands fa-apple"
                          size="md"
                          color="white"
                        />
                      </div>
                      <span
                        class="gsi-material-button-contents"
                        style="color: white"
                        >{{ $t("continue_with_apple") }}</span
                      >
                      <span style="display: none; color: white">{{
                        $t("continue_with_apple")
                      }}</span>
                    </div>
                  </button>
                </div>
                <v-col align="center">
                  <p class="body-2">
                    {{ $t("by_continuing_you_agree_to_our") }}
                    <router-link
                      :to="{ name: 'userterms' }"
                      @click.native="decline"
                      >{{ $t("terms_of_service") }}</router-link
                    >
                    {{ $t("and") }}
                    <router-link
                      :to="{ name: 'privacypolicy' }"
                      @click.native="decline"
                      >{{ $t("privacy_policy") }}</router-link
                    >
                  </p>
                </v-col>
              </v-card>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="2">
            <v-col class="pa-0">
              <h1 class="bold-h1">{{ $t("log_in") }}</h1>

              <v-form v-model="form_signIn" ref="general" class="ma-0 pa-0">
                <v-col class="pa-0">
                  <v-col>
                    <v-text-field
                      v-model="user.email"
                      disabled
                      clearable
                      :rules="textFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      hide-details
                      placeholder="Email"
                      persistent-hint
                  /></v-col>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      clearable
                      :append-inner-icon="
                        passwordVisible ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      :type="passwordVisible ? 'text' : 'password'"
                      @click:append-inner="passwordVisible = !passwordVisible"
                      :rules="passwordFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      :placeholder="$t('password')"
                      persistent-hint
                  /></v-col>
                  <v-btn
                    block
                    color="success"
                    elevation="0"
                    class="xl-button text-none"
                    size="large"
                    :disabled="signInWithEmailLoading"
                    :loading="signInWithEmailLoading"
                    @click="signInWithEmail"
                    >{{ $t("log_in") }}</v-btn
                  >
                </v-col>
              </v-form>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="3">
            <v-col class="pa-0" align="start">
              <h1 class="bold-h1">{{ $t("create_account") }}</h1>

              <v-form v-model="form_create" ref="form_create" class="ma-0 pa-0">
                <v-col class="pa-0">
                  <v-col>
                    <v-text-field
                      v-model="user.email"
                      disabled
                      clearable
                      :rules="textFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      single-line
                      hide-details
                      placeholder="Email"
                      persistent-hint
                  /></v-col>
                  <v-col>
                    <v-text-field
                      v-model="user.password"
                      clearable
                      :append-inner-icon="
                        passwordVisible ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      :type="passwordVisible ? 'text' : 'password'"
                      @click:append-inner="passwordVisible = !passwordVisible"
                      :rules="passwordFieldRules"
                      validate-on="submit"
                      color="primary"
                      bg-color="surface"
                      variant="outlined"
                      id="password"
                      single-line
                      :placeholder="$t('password')"
                      persistent-hint
                  /></v-col>
                  <v-col>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="user.firstName"
                          clearable
                          :rules="textFieldRules"
                          validate-on="submit"
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="$t('first_name')"
                          persistent-hint
                      /></v-col>
                      <v-col>
                        <v-text-field
                          v-model="user.lastName"
                          clearable
                          :rules="textFieldRules"
                          validate-on="submit"
                          color="primary"
                          bg-color="surface"
                          variant="outlined"
                          single-line
                          hide-details
                          :placeholder="$t('last_name')"
                          persistent-hint
                      /></v-col>
                    </v-row>
                  </v-col>
                  <v-col class="pb-0">
                    <v-phone-input
                      v-model="user.phone"
                      single-line
                      :label="$t('phone_number')"
                      :country-label="$t('country')"
                      defaultCountry="SE"
                      color="primary"
                      bg-color="surface"
                      :rules="textFieldRules"
                      validate-on="submit"
                      variant="outlined"
                      country-icon-mode="svg"
                    />
                  </v-col>
                  <v-checkbox
                    v-model="essential"
                    class="mt-0 pt-0"
                    single-line
                    color="surface"
                    inset
                    hide-details
                  >
                    <template v-slot:label>
                      <p class="body-2 ml-2">
                        {{ $t("special_offers_info") }}
                      </p>
                    </template></v-checkbox
                  >
                  <v-checkbox
                    v-model="marketing"
                    class="mt-0 pt-0 pb-2"
                    single-line
                    inset
                    color="surface"
                    hide-details
                  >
                    <template v-slot:label>
                      <p class="body-2 ml-2">
                        {{ $t("newsletter_tips_recipes") }}
                      </p>
                    </template></v-checkbox
                  >
                </v-col>

                <v-btn
                  block
                  color="success"
                  elevation="0"
                  size="large"
                  class="xl-button text-none"
                  :disabled="createAccountLoading"
                  :loading="createAccountLoading"
                  @click="createAccount"
                  >{{ $t("create_account") }}</v-btn
                >
              </v-form>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="4">
            <v-col>
              <h1 class="bold-h1 mb-4">{{ $t("validate_number") }}</h1>
              <v-card class="pa-4">
                <h4 class="bold-h4 mb-4">{{ $t("phone_number") }}</h4>
                <v-phone-input
                  :label="$t('phone_number')"
                  :country-label="$t('country')"
                  v-model="user.phone"
                  single-line
                  defaultCountry="SE"
                  color="primary"
                  :rules="textFieldRules"
                  :invalid-message="$t('phone_number_invalid')"
                  validate-on="submit"
                  variant="outlined"
                  country-icon-mode="svg"
                />
                <v-col class="pa-0">
                  <v-checkbox
                    v-model="essential"
                    class="mt-0 pt-0"
                    single-line
                    color="primary"
                    inset
                    hide-details
                  >
                    <template v-slot:label>
                      <p class="body-2 ml-2">
                        {{ $t("special_offers_info") }}
                      </p>
                    </template></v-checkbox
                  >
                  <v-checkbox
                    v-model="marketing"
                    class="mt-0 pt-0 pb-4"
                    single-line
                    color="primary"
                    inset
                    hide-details
                  >
                    <template v-slot:label>
                      <p class="body-2 ml-2">
                        {{ $t("newsletter_tips_recipes") }}
                      </p>
                    </template></v-checkbox
                  >
                </v-col>
                <v-btn @click="sendCodePhoneNumber">{{
                  $t("send_code")
                }}</v-btn>
              </v-card>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="5">
            <v-col>
              <h1>{{ $t("verify_your_account") }}</h1>
              <v-card
                class="py-8 px-6 text-center mx-auto ma-4"
                elevation="12"
                max-width="400"
                width="100%"
              >
                <div class="text-body-3">
                  {{ $t("verification_code_sent", { phone: user.phone }) }}
                </div>

                <v-sheet color="surface">
                  <v-otp-input
                    ref="otpInput"
                    v-model="otp"
                    variant="solo"
                  ></v-otp-input>
                </v-sheet>

                <v-btn
                  class="my-4"
                  color="primary"
                  height="40"
                  :text="$t('verify')"
                  variant="flat"
                  width="70%"
                  :loading="loadingVerify"
                  :disabled="loadingVerify"
                  @click="verifyPhoneNumber"
                ></v-btn>

                <div class="text-caption">
                  {{ $t("not_received_code") }}
                  <a href="#" @click.prevent="resendCode">{{
                    $t("send_again")
                  }}</a>
                </div>
              </v-card>
            </v-col>
          </v-window-item>
          <v-window-item disabled :value="6">
            <h1 class="bold-h2">{{ $t("phone_number_in_use") }}</h1>

            <p class="body-2 mb-10">
              {{ $t("login_link_sent") + " " + user.phone }}
            </p>
            <span class="body-4"
              >{{ $t("contact_for_further_issues") }}
              <span
                class="hover body-4"
                @click="
                  {
                    decline();
                    $router.push({ name: 'customerService' });
                  }
                "
              >
                {{ $t("customer_service") }}
              </span></span
            >
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserAPI from "@/api/UserAPI"; // Import the UserAPI
import { mapActions } from "vuex";
import { useI18n } from "vue-i18n";
import axios from "axios"; // Ensure you have axios imported
export default {
  name: "LoginPage",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  data() {
    return {
      user: {
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
        password: "",
        accountType: "email",
      },
      essential: true,
      marketing: true,
      email_from: false,
      form_create: false,
      otp: "",
      isGoogleLoading: false,
      passwordVisible: false,
      isAppleLoading: false,
      step: 1,
      loadingVerify: false,
      createAccountLoading: false,
      signInWithEmailLoading: false,
      firebaseUser: {},
      emailRules: [
        (value) => {
          if (value) return true;

          return this.t("email_is_required");
        },
        (value) => {
          if (/.+@.+\..+/.test(value)) return true;

          return this.t("email_must_be_valid");
        },
      ],
      passwordFieldRules: [
        (value) => {
          // Rule 1: Check if the field is not empty
          if (value) return true;
          return this.t("field_required");
        },
        (value) => {
          // Rule 2: Minimum length check (Firebase requires at least 6 characters)
          if (value.length >= 6) return true;
          return this.t("password_min_length");
        },
        (value) => {
          // Rule 3: Check if the password contains both letters and numbers
          const hasLetters = /[a-zA-Z]/.test(value);
          const hasNumbers = /\d/.test(value);
          if (hasLetters && hasNumbers) return true;
          return this.t("password_must_contain_letters_and_numbers");
        },
      ],
      textFieldRules: [
        (value) => {
          if (value) return true;
          return this.t("field_required");
        },
      ],
    };
  },
  props: ["visible"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    isLoading() {
      return this.isGoogleLoading || this.isAppleLoading;
    },
  },
  methods: {
    ...mapActions(["userLoggedIn", "userLoggedOut"]),
    decline() {
      this.show = false;
      setTimeout(() => {
        this.step = 1;
      }, 100);
    },

    goBack() {
      this.$router.go(-1);
    },
    async resendCode() {
      this.otp = "";
      await UserAPI.verifyPhoneNumber(this.user.phone);
    },

    async verifyPhoneNumber() {
      try {
        this.loadingVerify = true;
        const res = await UserAPI.verifyPhoneNumber(this.user.phone, this.otp);

        if (res == "approved") {
          const userExists = await UserAPI.checkUserExists({
            email: this.firebaseUser.user.email,
          });
          if (!userExists) {
            await UserAPI.registerNewUser(
              this.firebaseUser.user,
              this.user.accountType,
              this.user.phone,
              this.marketing,
              this.essential
            );
          }
          const { user, token, refreshToken } =
            await UserAPI.signInWithCredential(this.firebaseUser);
          await this.userLoggedIn({ user, token, refreshToken });
          await UserAPI.updateUser(this.firebaseUser.user.uid, {
            phone: this.user.phone,
            phoneVerified: true,
          });
          this.$emit("done");
          this.loadingVerify = false; // Pass refreshToken here
          this.decline();
        }
      } catch (error) {
        this.loadingVerify = false;
      }
    },
    async sendCodePhoneNumber() {
      const userExists = await UserAPI.checkUserExists({
        phone: this.user.phone.replaceAll("+", ""),
      });
      if (userExists) {
        this.phoneAlreadyExists();
        return;
      }
      await UserAPI.sendCodePhoneNumber(this.user.phone);
      this.createAccountLoading = false;
      this.step = 5;
      setTimeout(() => {
        const otpInput = this.$refs.otpInput;

        if (otpInput && otpInput.$el) {
          const inputElement = otpInput.$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 1000);
    },
    async checkAccountExists() {
      try {
        const { valid } = await this.$refs.email_from.validate();

        if (!valid) return;
        const userExists = await UserAPI.checkUserExists({
          email: this.user.email,
          accountType: "email",
        });
        this.user.accountType = "email";
        this.step = userExists ? 2 : 3;
      } catch (error) {
        console.error(error);
        // Check if the error is an Axios error
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";

          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        } else {
          // Handle non-Axios errors
          this.$store.dispatch("vtoast/showToast", {
            message: "An unexpected error occurred",
            color: "error",
          });
        }
        this.isGoogleLoading = false;
        this.isAppleLoading = false;
      }
    },
    async phoneAlreadyExists() {
      this.step = 6;
      await UserAPI.sendPhoneEmail({
        phone: this.user.phone.replaceAll("+", ""),
      });
    },
    async createAccount() {
      try {
        const { valid } = await this.$refs.form_create.validate();

        if (!valid) return;
        this.createAccountLoading = true;
        const userExists = await UserAPI.checkUserExists({
          phone: this.user.phone.replaceAll("+", ""),
        });

        if (userExists) {
          this.phoneAlreadyExists();
          this.createAccountLoading = false;
          return;
        }

        const { firebaseUser } = await UserAPI.signUpWithEmail(
          this.user.email,
          this.user.password,
          this.user.firstName,
          this.user.lastName
        );
        this.firebaseUser = firebaseUser;
        this.sendCodePhoneNumber();
      } catch (error) {
        if (error.code) {
          if (error.code == "auth/email-already-in-use") {
            try {
              const { firebaseUser } = await UserAPI.signInWithEmail(
                this.user.email,
                this.user.password
              );
              this.firebaseUser = firebaseUser;
              this.sendCodePhoneNumber();
              return;
            } catch (error) {
              this.$store.dispatch("vtoast/showToast", {
                message: this.t(error.code),
                color: "error",
              });
            }
          } else {
            this.$store.dispatch("vtoast/showToast", {
              message: this.t(error.code),
              color: "error",
            });
          }
        }
        this.createAccountLoading = false;
      }
    },
    async signInWithEmail() {
      try {
        this.signInWithEmailLoading = true;
        const { firebaseUser } = await UserAPI.signInWithEmail(
          this.user.email,
          this.user.password
        );
        this.firebaseUser = firebaseUser;

        const resp = await UserAPI.getUser(this.firebaseUser.user.uid);

        if (resp.phoneVerified == undefined || resp.phoneVerified == false) {
          this.step = 4;
          this.signInWithEmailLoading = false;
          return;
        }
        const { user, token, refreshToken } =
          await UserAPI.signInWithCredential(this.firebaseUser);
        this.userLoggedIn({ user, token, refreshToken });
        this.$emit("done");
        this.decline();
        this.signInWithEmailLoading = false;
      } catch (error) {
        if (error.code) {
          this.$store.dispatch("vtoast/showToast", {
            message: this.t("auth/wrong-password"),
            color: "error",
          });
        }
        this.signInWithEmailLoading = false;
      }
    },
    async signInWithGoogle() {
      this.isGoogleLoading = true;
      this.user.accountType = "google";
      try {
        const { firebaseUser } = await UserAPI.signInWithGoogle();
        this.firebaseUser = firebaseUser;
        let email = this.firebaseUser.user.email;

        if (!email) {
          const providerData = this.firebaseUser.user.providerData || [];
          const index = providerData.findIndex((e) => e.email != null);
          email = index !== -1 ? providerData[index].email : null;
        }
        const userExists = await UserAPI.checkUserExists({
          email: email,
          accountType: "google",
        });
        if (!userExists) {
          this.user.accountType = "google";
          this.step = 4;
          return;
        }

        const resp = await UserAPI.getUser(this.firebaseUser.user.uid);

        if (resp.phoneVerified == undefined || resp.phoneVerified == false) {
          this.step = 4;
          return;
        }
        const { user, token, refreshToken } =
          await UserAPI.signInWithCredential(this.firebaseUser);
        this.userLoggedIn({ user, token, refreshToken });
        this.$emit("done");
        this.isGoogleLoading = false;
        this.decline();
      } catch (error) {
        this.isGoogleLoading = false;
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";

          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        } else {
          // Handle non-Axios errors
          this.$store.dispatch("vtoast/showToast", {
            message: "An unexpected error occurred",
            color: "error",
          });
        }
      }
    },
    async signInWithApple() {
      this.isAppleLoading = true;
      try {
        const { firebaseUser } = await UserAPI.signInWithApple();
        this.firebaseUser = firebaseUser;
        let email = this.firebaseUser.user.email;

        if (!email) {
          const providerData = this.firebaseUser.user.providerData || [];
          const index = providerData.findIndex((e) => e.email != null);
          email = index !== -1 ? providerData[index].email : null;
        }
        const userExists = await UserAPI.checkUserExists({
          email: email,
          accountType: "apple",
        });
        if (!userExists) {
          this.user.accountType = "apple";
          this.step = 4;
          return;
        }

        const resp = await UserAPI.getUser(this.firebaseUser.user.uid);

        if (resp.phoneVerified == undefined || resp.phoneVerified == false) {
          this.step = 4;
          return;
        }
        const { user, token, refreshToken } =
          await UserAPI.signInWithCredential(this.firebaseUser);
        this.userLoggedIn({ user, token, refreshToken });
        this.$emit("done");
        this.isAppleLoading = false;
        this.decline();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // Extract the reason from the error response
          const reason = error.response?.data?.reason || "An error occurred";

          this.$store.dispatch("vtoast/showToast", {
            message: this.t(reason),
            color: "error",
            timer: 5000,
          });
        } else {
          // Handle non-Axios errors
          this.$store.dispatch("vtoast/showToast", {
            message: "An unexpected error occurred",
            color: "error",
          });
        }
        this.isAppleLoading = false;
      }
    },
  },
  async beforeMount() {
    if (this.$route.meta.dialog != undefined) {
      this.show = this.$route.meta.dialog;
    } else {
      this.show = true;
    }
  },
};
</script>

<style scoped>
.hover:hover {
  text-decoration: underline;
}
.v-main {
  background-color: #003a70;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container {
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center; /* Center align the content */
}

.logo-container {
  margin-bottom: 100px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-image {
  max-width: 500px;
  margin: auto 10px auto 40px;
}

.form-group {
  margin-bottom: 1rem;
}

.email-form input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
}

.login-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #555;
}

.oauth-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.google-button {
  background-color: #db4437;
}

.apple-button {
  background-color: #a3aaae;
}

.google-button:hover,
.apple-button:hover {
  opacity: 0.85;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.back-button {
  position: fixed;
  top: 10px;
  left: 10px;
  color: white;
  background-color: transparent;
}

.apple-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "agenda-light", Arial, sans-serif;
  font-size: 20px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 22px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "agenda-light", Arial, sans-serif;
  font-size: 20px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 22px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "agenda-light", Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.apple-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "agenda-light", Arial, sans-serif;
  font-size: 20px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 22px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.apple-material-button .apple-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.apple-material-button .apple-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.apple-material-button .apple-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "agenda-light", Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.apple-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.apple-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.apple-material-button:disabled .apple-material-button-contents {
  opacity: 38%;
}

.apple-material-button:disabled .apple-material-button-icon {
  opacity: 38%;
}

.apple-material-button:not(:disabled):active .apple-material-button-state,
.apple-material-button:not(:disabled):focus .apple-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.apple-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.apple-material-button:not(:disabled):hover .apple-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>
