/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App
 */

import router from "@/plugins/router"; // Import the router
import Vue, { createApp } from "vue";
import App from "./App.vue";
import store from "./store.js";
import VueGoogleMaps from "vue-google-maps-community-fork";
import { registerPlugins } from "@/plugins";
import "./styles/style.scss";
import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";
import { createVPhoneInput } from "v-phone-input";
import i18n from "@/plugins/i18n";
import Vue3Lottie from "vue3-lottie";
import * as Sentry from "@sentry/vue";
import { createHead } from "@unhead/vue";
import { auth } from "./plugins/firebaseconfig.js";
import VueGtag from "vue-gtag";
import { sanitizeNumber } from "./plugins/sanitizeNumber";
import { cookieBot } from "@ambitiondev/vue-cookiebot";
import { createGtm } from "@gtm-support/vue-gtm";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import * as brandsIcons from "@fortawesome/free-brands-svg-icons";

// Add all brand icons to the library
Object.values(brandsIcons).forEach((icon) => {
  // Ensure the object is of type IconDefinition
  if ((icon as any).iconName) {
    library.add(icon as any);
  }
});

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
});

const app = createApp(App);
app.directive("sanitize-number", sanitizeNumber);
const head = createHead();

const isProduction = import.meta.env.VITE_APP_ENV === "production";

const gtag = import.meta.env.VITE_APP_GTAG;
const cookieBotId = import.meta.env.VITE_APP_COOKIEBOTID;
const google_key = import.meta.env.VITE_APP_GOOGLE_KEY;

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_ADDRESS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(), // Make sure to use the correct integration method if needed
  ],
  environment: import.meta.env.VITE_APP_FIREBASE_DEVELOPMENT,
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 0, // 10% in production, 0% otherwise
  replaysOnErrorSampleRate: isProduction ? 1.0 : 0, // 100% in production, 0% otherwise
});
// Register other plugins
registerPlugins(app);
library.add(fas);
library.add(far);


// Register the Stripe plugin
app.component("font-awesome-icon", FontAwesomeIcon);
// Use Vuex store
app.use(store);

// Use Vue Router
app.use(router);
app.use(head);
app.use(vPhoneInput);
app.use(i18n);
app.use(Vue3Lottie);
app.use(VueGoogleMaps, {
  load: {
    key: google_key,
    libraries: "places",
  },
});

app.use(cookieBot, {
  cookieBotId: cookieBotId,
});

app.use(
  createGtm({
    id: gtag,
    defer: false,
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
  })
);
app.use(
  VueGtag,
  {
    config: {
      id: gtag,
    },
  },
  router
);
// Dispatch the initializeApp action
store.dispatch("initializeApp");
auth.onAuthStateChanged(async (user) => {
  if (!user) {
    if (router.currentRoute.value.meta.requiresAuth) {
      router.push({ name: "handla" });
    }
  }

  store.dispatch("fetchUser");
});
// Mount the app
app.mount("#app");
